<template>
  <div
    class="practice-play-card ma-sm-5 ma-1"
    @mouseleave="rotate = false"
    :style="{
      '--themecolor': themeColor,
      '--card-height': image ? '22rem' : '20rem',
      '--title-font-size': image ? '1rem' : '1.5rem',
      '--title-font-weight': image ? '600' : '800'
    }"
  >
    <div :class="sideClass('front')">
      <v-img
        v-if="image"
        class="practice-play-card__side-img"
        :src="image"
        contain
      ></v-img>
      <span class="practice-play-card__side-title"
        >{{ practicePlayData.name[$i18n.locale] }}
        <div class="practice-play-card__side-title-underline"></div>
      </span>
      <p>
        {{
          practicePlayData.description[$i18n.locale] ||
          practicePlayData.description.en
        }}
      </p>
      <div class="practice-play-card__side--action-block">
        <router-link
          class="mr-2 text-decoration-none"
          :to="`/practice/${practicePlayData.categoryId}/${practicePlayData.id}`"
        >
          <v-btn rounded>Play</v-btn>
        </router-link>

        <v-icon
          v-if="practicePlayData.studyMaterials.length > 0"
          class="ml-2"
          @click="rotate = !rotate"
          >mdi-book-open-page-variant</v-icon
        >
        <v-icon v-if="practicePlayData.pdf" class="ml-2"
          >mdi-book-open-page-variant</v-icon
        >
        <v-icon v-if="practicePlayData.video" class="ml-2"
          >mdi-filmstrip</v-icon
        >
        <v-icon v-if="practicePlayData.audio" class="ml-2"
          >mdi-volume-high</v-icon
        >
      </div>
    </div>
    <div :class="sideClass('back')">
      <span class="practice-play-card__side-title mb-3"
        >Study Material
        <div class="practice-play-card__side-title-underline"></div>
      </span>

      <a
        :href="m.url[$i18n.locale] || m.url.en"
        target="_blank"
        class="materialLink"
        v-for="(m, index) in practicePlayData.studyMaterials"
        :key="m.text.en"
      >
        {{ m.text[$i18n.locale] }}
      </a>

      <div class="practice-play-card__side--action-block">
        <router-link
          class="mr-2 text-decoration-none"
          :to="`/practice/${practicePlayData.categoryId}/${practicePlayData.id}`"
        >
          <v-btn rounded>Practice</v-btn>
        </router-link>
        <v-icon @click="rotate = false" class="ml-1 mr-1"
          >mdi-rotate-3d-variant</v-icon
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: { required: false },
    practicePlayData: { type: Object, required: true },
    seq: {
      type: Number,
      default: 1
    },
    themeColor: { type: String, default: 'red' }
  },
  data() {
    return {
      rotate: false
    }
  },
  computed: {},
  methods: {
    sideClass(side) {
      let classes = `practice-play-card__side practice-play-card__side--${side}`
      if (this.rotate) {
        classes += ` practice-play-card__side--${side}-rotate`
      }
      return classes
    }
  }
}
</script>

<style lang="scss" scoped>
$color-black: #000; // var(--v-primary-base);
$color-white: #fff;

.practice-play-card {
  perspective: 40em;
  -moz-perspective: 40rem;
  position: relative;
  height: var(--card-height);
  width: 15rem;
  transform-style: preserve-3d;
  transition: all 0.2s;

  &:hover {
    transform: scale(1.01) translateY(-10px);
  }
  &__side {
    height: 100%;
    transition: transform 0.5s ease-in-out;
    color: $color-black;
    position: absolute;
    padding: 1rem;
    top: 0;
    left: 0;
    width: 100%;
    text-align: left;
    backface-visibility: hidden;
    border-right: 1px solid;
    border-top: 5px solid;
    border-color: var(--themecolor);
    border-radius: 20px;
    box-shadow: 0 0.5rem 2rem rgba($color-black, 0.15);

    &-img {
      border-radius: 20px;
      margin-bottom: 10px;
    }

    &-title {
      font-size: var(--title-font-size);
      font-weight: var(--title-font-weight);
      position: relative;
      display: block;
      margin-bottom: 5px;

      &-underline {
        height: 4px;
        background-color: var(--themecolor);
        width: 10%;
      }
    }

    &--front {
      &-rotate {
        transform: rotateY(-180deg) !important;
      }
    }
    &--back {
      transform: rotateY(180deg);

      &-rotate {
        transform: rotateY(0deg) !important;
      }
      &-title {
        font-size: 2rem;
        font-weight: 500;
        position: relative;
        display: block;
        margin-bottom: 10px;
      }
    }

    &--action-block {
      position: absolute;
      bottom: 1rem;

      .v-btn {
        color: $color-white;
        background-color: var(--themecolor);

        &:not(:last-child) {
          margin-right: 5px;
        }
      }
      .v-icon {
        color: var(--themecolor);
        filter: opacity(0.5);
      }
    }
    &--action-study {
      color: var(--themecolor) !important;
      background-color: $color-white !important;
    }
  }

  &:hover &__side--front {
    transform: none;
  }

  &:not(:hover) .practice-play-card__side--back {
    transform: rotateY(193deg);
  }
}

.materialLink {
  padding: 3px;
  border: 1.5px solid black;
  border-radius: 5px;
  display: inline-block;
  text-decoration: none;
  color: $color-black;

  &:not(:last-child) {
    margin-right: 5px;
    margin-bottom: 5px;
  }
  &:hover {
    background-color: var(--themecolor);
    color: white;
  }
}
</style>
